import React from 'react'

import CahFlowImage from '../images/other/cash-flow.png'
import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'
import BotButton from '../components/landBot/BotButton'
import ParallaxContainer from '../components/parallax/ParallaxContainer'
import GoogleRating from '../components/general/GoogleRating'
import FeaturedSection from '../components/layout/FeaturedSection'

const Pay = ({ location }) => (
    <Layout location={location} gutters={false} container={false} noNavigation>
        <Header title="Home - Find your perfect office space" />

        <div
            className="grid grid-cols-3 xl:ml-16 relative mb-0"
            style={{ gridTemplateRows: 'auto' }}
        >
            <div className="col-start-1 col-end-4 row-start-1 row-end-2 text-left mt-24 mb-8 md:-mb-24 sm:mt-4 md:-mt-16 flex items-start flex-col justify-center mx-0 md:w-10/12 sm:col-end-3">
                <div className="max-w-full mx-auto md:ml-0">
                    <h1 className=" transition-quick text-3xl md:text-5xl text-center sm:text-left mx-8 md:mx-0 sm:text-left font-bold">
                        Cash is king! Boost your cash flow by paying your office rent by credit card
                    </h1>
                </div>
            </div>
            <div
                className="hidden sm:block justify-self-end col-start-2 col-end-4 row-start-1 row-end-2 lg:row-end-3 relative"
                style={{
                    width: '65%',
                    maxWidth: '500px',
                }}
            >
                <div className="relative">
                    <img src={CahFlowImage} alt="Cash flow" />
                </div>
            </div>
            <div className="col-start-1 col-end-4 row-start-2 row-end-3  relative">
                <ParallaxContainer className="flex-col pt-16 sm:pt-4 no-wrap" weighting={2} reverse>
                    <div
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-offset={-100}
                        className="absolute flex items-start bottom-0 mx-auto sm:mx-0 px-8 sm:px-0"
                    >
                        <div className="relative left-0 top-36">
                            <BotButton
                                type="secondary"
                                botName="PayLandBot"
                                className="text-center font-bold shadow-charlie sm:text-2xl"
                            >
                                Pay your office rent by credit card
                            </BotButton>
                            <p className="text-center text-xs">
                                (for just 1.9% tax deductible fee. Amex and other major credit cards accepted*)
                            </p>
                            <div className="relative left-60 top-8 flex items-center">
                                <GoogleRating variant="extended" />
                            </div>
                        </div>
                    </div>
                </ParallaxContainer>
            </div>
        </div>

        <FeaturedSection className="max-w-6xl mx-auto md:px-6 my-24 mt-40vh lg:mt-8vh" variant="concise" />

        <p className="text-xs mx-auto max-w-6xl leading-tight">
            *United Kingdom only. Subject to card network limitations, your card issuers T&Cs and their respective
            reward schemes. There is a 1.9% tax deductible fee. Please consult with your tax adviser to confirm
            that this expense is qualified for a tax deduction.
        </p>
    </Layout>
)

export default Pay
